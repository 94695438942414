import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { FiXCircle } from 'react-icons/fi'
import '@reach/dialog/styles.css'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Callout from '../components/callout'

const GalleryPage = ({ data }) => {
  const [showSlideshow, setShowSlideshow] = useState(false)
  const [image, setImage] = useState()
  const open = name => {
    setShowSlideshow(true)
    setImage(() => data.fullSize.nodes.find(node => name === node.name))
  }
  const close = () => setShowSlideshow(false)
  const images = [
    `gallery1`,
    `gallery2`,
    `gallery3`,
    `gallery4`,
    `gallery5`,
    `gallery6`,
    `gallery7`,
    `gallery8`,
    `gallery9`,
    `gallery10`,
    `gallery11`,
    `gallery12`,
    `gallery13`,
    `gallery14`,
    `gallery15`,
    `gallery16`,
    `gallery17`,
    `gallery18`,
  ]

  return (
    <Layout>
      <SEO title="Gallery" />
      <div className="container">
        <h1 className="text-center text-purple-300 mb-6 lg:mb-12 text-lg lg:text-xl">
          Be Inspired
        </h1>
        <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-2 mb-12 lg:mb-24">
          {images.map((image, index) => {
            const thumbnail = data.thumbnails.nodes.find(
              item => item.name === image
            )

            // Bail if not found
            if (typeof thumbnail === 'undefined') {
              return
            }

            return (
              <li key={index}>
                <button
                  aria-label="open slide show"
                  className="block w-full h-full"
                  onClick={() => open(thumbnail.name)}
                >
                  <Img
                    alt=""
                    className="shadow"
                    fluid={thumbnail.childImageSharp.fluid}
                  />
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <DialogOverlay
        className="z-40"
        isOpen={showSlideshow}
        onDismiss={close}
        style={{ backgroundColor: 'hsla(0, 100%, 0%, 0.85)' }}
      >
        <DialogContent
          aria-label="gallery"
          className="max-w-3xl"
          style={{ width: `100%` }}
        >
          {image && <img alt="" src={image.publicURL} />}

          <button
            aria-label="close"
            className="absolute z-50 text-white"
            onClick={close}
            style={{ top: `1rem`, right: `1rem` }}
          >
            <FiXCircle size={30} />
          </button>
        </DialogContent>
      </DialogOverlay>
      <Callout />
    </Layout>
  )
}

export const query = graphql`
  query {
    thumbnails: allFile(
      filter: { relativeDirectory: { eq: "gallery/thumbnails" } }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    fullSize: allFile(
      filter: { relativeDirectory: { eq: "gallery/fullSize" } }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default GalleryPage
